<template>
  <div class="daterange-wrap v-date-range mx-2">
    <v-menu v-model="menu" :close-on-content-click="false" v-bind="menuProps">
      <template v-slot:activator="{ on }">
        <v-text-field
          prepend-inner-icon="mdi-calendar-text"
          class="v-date-range__input-field"
          :value="inputValue"
          readonly
          :disabled="disabled"
          :placeholder="placeholder"
          v-bind="inputProps"
          v-on="on"
        ></v-text-field>
      </template>

      <v-card class="v-date-range__menu-content">
        <v-card-text>
          <div
            :data-days="highlightDates.length"
            :class="{
              'v-date-range__pickers': true,
              'v-date-range--highlighted': highlightDates.length,
            }"
          >
            <v-card-title v-if="$slots.title">
              <slot name="title" v-if="$slots.title"></slot>
            </v-card-title>
            <div class="pa-0">
              <div :class="$vuetify.breakpoint.mdAndUp ? 'v-date-range__content' : ''">
                <v-list v-if="!noPresets" dense class="mr-4">
                  <!-- <v-subheader>{{ presetLabel }}</v-subheader> -->
                  <v-list-item
                    class="px-1"
                    v-for="(preset, index) in presets"
                    v-model="isPresetActive[index]"
                    :key="index"
                    @click="selectPreset(index)"
                  >
                    <v-list-item-content>
                      {{ preset.label }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <div style="height: 20px; margin-right: -40px; margin-top: 5px">
                  <h5>Start</h5>
                </div>
                <v-date-picker
                  class="mr-4 v-date-range__picker--start v-date-range__picker"
                  v-model="pickerStart"
                  :locale="localeSettings"
                  :first-day-of-week="firstDayOfWeek"
                  :min="min"
                  :max="pickerEnd || max"
                  :no-title="noTitle"
                  :next-icon="nextIcon"
                  :prev-icon="prevIcon"
                  :events="highlightDates"
                  :event-color="highlightClasses"
                  width="290"
                ></v-date-picker>

                <div style="height: 20px; margin-right: -40px; margin-top: 5px">
                  <h5>End</h5>
                </div>
                <v-date-picker
                  class="v-date-range__picker--end v-date-range__picker"
                  v-model="pickerEnd"
                  :locale="localeSettings"
                  :first-day-of-week="firstDayOfWeek"
                  :min="pickerStart || min"
                  :max="max"
                  :no-title="noTitle"
                  :next-icon="nextIcon"
                  :prev-icon="prevIcon"
                  :events="highlightDates"
                  :event-color="highlightClasses"
                  width="290"
                ></v-date-picker>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="reset">{{ $t("t_reset") }}</v-btn>
          <v-btn text @click="menu = false">{{ $t("t_cancel") }}</v-btn>
          <v-btn @click="applyRange" color="primary" :disabled="!bothSelected">{{ $t("t_apply") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import { DateTime } from "luxon";

const DEFAULT_DATE = DateTime.now().toISODate().toString();

export default {
  name: "date-range",
  props: {
    // Take start and end as the input. Passable via v-model.
    value: {
      type: Object,
      default: () => {
        return { start: DEFAULT_DATE, end: DEFAULT_DATE };
      },
    },
    dateRangeInit: {
      type: Object,
      default: () => {
        return { start: DEFAULT_DATE, end: DEFAULT_DATE };
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    noPresets: {
      type: Boolean,
      default: false,
    },
    // Denotes the Placeholder string for start date.
    startLabel: {
      type: String,
      default: function () {
        return this.$t("t_start_date");
      },
    },
    // Denotes the Placeholder string for start date.
    endLabel: {
      type: String,
      default: function () {
        return this.$t("t_end_date");
      },
    },
    // The string that gets placed between `startLabel` and `endLabel`
    separatorLabel: {
      type: String,
      default: function () {
        return this.$t("t_to");
      },
    },
    presetLabel: {
      type: String,
      default: () => {
        return this.$t("t_presets");
      },
    },
    /**
     * Following values are all passable to vuetify's own datepicker
     * component.
     */
    // Min selectable date.
    min: {
      type: String,
      default: undefined,
    },
    // Max selectable date
    max: {
      type: String,
      default: undefined,
    },
    // Locale
    locale: {
      type: String,
      default: "en-US",
    },
    firstDayOfWeek: {
      type: [String, Number],
      default: 0,
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
    displayFormat: {
      type: String,
    },
    highlightColor: {
      type: String,
      default: "blue lighten-5",
    },
    showReset: {
      type: Boolean,
      default: true,
    },
    /**
     * Icons
     */
    nextIcon: {
      type: String,
      default: "$vuetify.icons.next",
    },
    prevIcon: {
      type: String,
      default: "$vuetify.icons.prev",
    },
    inputProps: {
      type: Object,
      default: () => {
        return {};
      },
    },
    menuProps: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    this.datesInit = {};
    return {
      menu: false,
      pickerStart: this.value.start,
      pickerEnd: this.value.end,
      highlightDates: [],
      highlightClasses: {},
    };
  },
  computed: {
    inputValue() {
      let value = this.value;

      if (!value.start && this.datesInit) {
        value = this.datesInit;
      }
      if (!value) {
        return "";
      }
      const start = this.displayFormat ? this.formatDate(value.start, this.displayFormat) : value.start;
      const end = this.displayFormat ? this.formatDate(value.end, this.displayFormat) : value.end;

      let label = `    ${start}    ${this.separatorLabel}     ${end}  `;

      return label;
    },
    placeholder() {
      return `    ${this.startLabel}    ${this.separatorLabel}    ${this.endLabel}  `;
    },
    /**
     * If the user has selected both the dates or not
     */
    bothSelected() {
      return this.pickerStart && this.pickerEnd;
    },
    isPresetActive() {
      return this.presets.map((preset) => preset.range[0] === this.pickerStart && preset.range[1] === this.pickerEnd);
    },
    localeSettings() {
      return this.$store.state?.settings?.lang;
    },
    presets() {
      return [
        {
          label: this.$t("t_today"),
          range: [DateTime.now().startOf("day").toString(), DateTime.now().endOf("day").toString()],
        },
        {
          label: this.$t("t_yesterday"),
          range: [
            DateTime.now().minus({ days: 1 }).startOf("day").toString(),
            DateTime.now().minus({ days: 1 }).endOf("day").toString(),
          ],
        },
        {
          label: this.$t("t_this_week"),
          range: [DateTime.now().startOf("week").toString(), DateTime.now().toString()],
        },
        {
          label: this.$t("t_last_week"),
          range: [
            DateTime.now().minus({ weeks: 1 }).startOf("week").toString(),
            DateTime.now().minus({ weeks: 1 }).endOf("week").toString(),
          ],
        },
        {
          label: this.$t("t_last_2_weeks"),
          range: [DateTime.now().minus({ weeks: 1 }).startOf("week").toString(), DateTime.now().toString()],
        },
        {
          label: this.$t("t_this_month"),
          range: [DateTime.now().startOf("month").toString(), DateTime.now().endOf("month").toString()],
        },
        {
          label: this.$t("t_last_month"),
          range: [
            DateTime.now().minus({ months: 1 }).startOf("month").toString(),
            DateTime.now().minus({ months: 1 }).endOf("month").toString(),
          ],
        },
        {
          label: this.$t("t_last_30_days"),
          range: [
            DateTime.now().minus({ days: 30 }).startOf("day").toString(),
            DateTime.now().minus({ days: 1 }).endOf("day").toString(),
          ],
        },
        {
          label: this.$t("t_this_year"),
          range: [DateTime.now().startOf("year").toString(), DateTime.now().toString()],
        },
      ];
    },
  },
  methods: {
    /**
     * Emit the input event with the updated range data.
     * This makes v-model work.
     */
    applyRange() {
      this.menu = false;
      this.emitRange();
    },
    /**
     * Called every time the menu is closed.
     * It also emits an event to tell the parent
     * that the menu has closed.
     *
     * Upon closing the datepicker values are set
     * to the current selected value.
     */
    closeMenu() {
      // Reset the changed values for datepicker models.
      this.pickerStart = this.value.start;
      this.pickerEnd = this.value.end;
      this.highlight();
      this.$emit("menu-closed");
    },
    formatDate(date, fmt) {
      return DateTime.fromISO(date).toLocaleString(fmt);
      //return format(parse(date, ISO_FORMAT, new Date()), fmt);
    },
    highlight() {
      if (!this.bothSelected) {
        return;
      }
      const dates = [];
      const classes = {};
      const start = DateTime.fromISO(this.pickerStart);
      const end = DateTime.fromISO(this.pickerEnd);
      const diff = end.diff(start).as("days");
      // Loop though all the days in range.
      for (let i = 0; i <= diff; i++) {
        const date = start.plus({ days: i }).toISODate().toString();
        dates.push(date);
        const classesArr = [];
        classesArr.push(`v-date-range__in-range`);
        classesArr.push(this.highlightColor);
        i === 0 && classesArr.push(`v-date-range__range-start`);
        i === diff && classesArr.push(`v-date-range__range-end`);
        classes[date] = classesArr.join(" ");
      }
      this.highlightDates = dates;
      this.highlightClasses = classes;
    },
    selectPreset(presetIndex) {
      this.pickerStart = this.presets[presetIndex].range[0];
      this.pickerEnd = this.presets[presetIndex].range[1];
      this.applyRange();
    },
    reset() {
      // Reset Picker Value
      let startDate = DateTime.now().startOf("week").toISO().toString();
      let endDate = DateTime.now().toISO().toString();
      this.pickerStart = startDate;
      this.pickerEnd = endDate;
      this.highlightDates = [];
      this.highlightClasses = {};
      this.emitRange();
    },
    emitRange() {
      this.$emit("input", {
        start: this.pickerStart,
        end: this.pickerEnd,
      });
    },
  },
  watch: {
    // Watching to see if the menu is closed.
    menu(isOpen) {
      if (!isOpen) {
        this.closeMenu();
      } else {
        this.highlight();
      }
    },
    pickerStart: "highlight",
    pickerEnd: "highlight",
  },
  created() {
    this.datesInit = this.dateRangeInit;
  },
};
</script>
<style scoped lang="sass">
.v-menu__content
  &.menuable__content__active
    z-index: 99999
    margin-top: -15px
.v-date-range__menu-content
  margin-top: 0px
  border: 1px solid #565656
.v-date-range__input-field input
  text-align: center

.v-date-range__content
  display: flex
  .v-date-picker-table
    .v-btn
      border-radius: 0

.v-date-range__pickers
  &.v-date-picker-table__events
    height: 100%
    width: 100%
    top: 0
    z-index: -1

.v-date-range__pickers
  &.v-date-picker-table table
    width: auto
    margin: auto
    border-collapse: collapse

    & th, & td
      height: 32px
      width: 32px

    & td
      .v-btn
        &.v-btn--outline
          border: none
          box-shadow: 0 0 0 1px currentColor inset
        &.v-btn--active::before
          background-color: transparent !important
</style>
<style lang="scss">
.theme--dark {
  .daterange-wrap {
    .v-input__slot {
      box-shadow: none;
      background-color: #363636 !important;
      border: 1px solid #c3c3c3;
    }
    input {
      cursor: pointer;
      font-weight: bold;
    }
  }
}
</style>
