<template>
  <div class="bg-app py-5 vh-100 pt-1">
    <v-container class="py-0 px-0" fluid>
      <v-alert
        v-if="!$online"
        dense
        tile
        color="gray"
        icon="mdi-alert-circle-outline"
        class="alert-offline text-headline"
      >
        {{ $t("t_no_connection") }}
      </v-alert>
      <v-row>
        <v-col class="mx-3"
          ><p class="headline px-2 my-2 mb-4">{{ $t("v_reports.t_timeclock_report") }}</p>

          <DateRange
            ref="dateRange"
            v-model="range"
            :style="$vuetify.breakpoint.mdAndUp ? 'width: 300px' : '100%'"
            display-format="dd-MM-yyyy"
            no-title
            :input-props="inputProps"
            :menu-props="menuProps"
            :dateRangeInit="dateRangeInit"
            :start-label="$t('v_reports.t_start_date')"
            :end-label="$t('v_reports.t_end_date')"
            preset-label=""
            highlight-color="blue lighten-2"
          ></DateRange>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex mx-5 py-0 px-4">
          <v-select
            class="mr-2 report-select"
            v-model="jobFilterSelect"
            :items="jobFilter"
            item-text="name"
            item-value="id"
            :menu-props="{ maxHeight: '400' }"
            return-object
            :label="$t('v_reports.t_jobs')"
            multiple
            @input="getChart"
          >
            <template v-slot:selection="{ item, index }">
              <span class="caption" v-if="index === 0">{{ item.name | truncate(5) }}...</span>
              <span v-if="index === 1" class="grey--text text-caption"> (+{{ jobFilterSelect.length - 1 }}) </span>
            </template>
          </v-select>
          <v-select
            class="mr-2 report-select"
            v-model="codeFilterSelect"
            :items="codeFilter"
            item-text="name"
            item-value="id"
            :menu-props="{ maxHeight: '400' }"
            return-object
            :label="$t('v_reports.t_code')"
            multiple
            @input="getChart"
          >
            <template v-slot:selection="{ item, index }">
              <span class="caption" v-if="index === 0">{{ item.name | truncate(5) }}..</span>
              <span v-if="index === 1" class="grey--text text-caption"> (+{{ codeFilterSelect.length - 1 }}) </span>
            </template>
          </v-select>
          <v-select
            class="report-select"
            v-model="categoryFilterSelect"
            :items="categoryFilter"
            item-text="name"
            item-value="id"
            :menu-props="{ maxHeight: '400' }"
            return-object
            :label="$t('v_reports.t_category')"
            multiple
            @input="getChart"
          >
            <template v-slot:selection="{ item, index }">
              <span class="caption" v-if="index === 0">{{ item.name | truncate(5) }}...</span>
              <span v-if="index === 1" class="grey--text text-caption"> (+{{ categoryFilterSelect.length - 1 }}) </span>
            </template>
          </v-select>
          <v-btn class="mt-6 ml-2" x-small @click="resetChart()">{{ $t("t_reset") }}</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0" :class="!$vuetify.breakpoint.mobile ? 'py-0' : ''">
          <v-card class="pb-0 mx-1" flat>
            <v-row class="py-0">
              <div class="col-sm-5 col-xs-12 pb-0" v-if="sumTotal && sumTotal > 0">
                <p class="text-h6 mx-5 mb-0">{{ $t("v_reports.t_hours") }} {{ sumTotal }}</p>
              </div>
            </v-row>
            <v-row>
              <v-col class="pt-0 mx-5">
                <div class="chart-wrap" v-if="sumTotal && sumTotal > 0">
                  <BarChart
                    v-if="!loading"
                    :chartdata="chartdata"
                    :options="options"
                    :styles="{ height: '40vh', position: 'relative', 'max-width': '95%' }"
                  />
                </div>
                <div v-else-if="!loading" class="text-h6 mt-3">
                  {{ $t("v_reports.t_no_timeclocks_for_selection") }}
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { DateTime } from "luxon";
import Helpers from "@/mixins/helpers";
import BarChart from "@/components/Chart.vue";
import DateRange from "@/components/DateRange.vue";
import { TimeClockService } from "@/services";
export default {
  name: "Reports",
  metaInfo: {
    title: "Reports",
  },
  mixins: [Helpers],
  components: {
    BarChart,
    DateRange,
  },
  computed: {
    jobFilter: function () {
      let p = this.getProps(this.timeClocks, "jobId");
      let jobFilter = p.map((t) => ({ id: t.jobId, name: t.jobName }));
      for (const obj of jobFilter) {
        if (obj.name === undefined || obj.name === null) {
          obj.name = "*No job";
        }
      }
      jobFilter.sort((a, b) => a.name.localeCompare(b.name));
      return jobFilter;
    },
    codeFilter: function () {
      let p = this.getProps(this.timeClocks, "costCodeId");
      let codeFilter = p.map((t) => ({ id: t.costCodeId, name: t.costCodeCode }));
      for (const obj of codeFilter) {
        if (obj.name === undefined || obj.name === null) {
          obj.name = "*No cost code";
        }
      }
      codeFilter.sort((a, b) => a.name.localeCompare(b.name));
      return codeFilter;
    },
    categoryFilter: function () {
      let p = this.getProps(this.timeClocks, "timeCategoryId");
      let categoryFilter = p.map((t) => ({ id: t.timeCategoryId, name: t.timeCategoryName }));
      for (const obj of categoryFilter) {
        if (obj.name === undefined || obj.name === null) {
          obj.name = "*No category";
        }
      }
      categoryFilter.sort((a, b) => b.name - a.name);
      return categoryFilter;
    },
    dateRangeInit: function () {
      return { start: this.startDate.toString(), end: this.endDate.toString() };
    },
  },
  watch: {
    range: {
      handler(range) {
        const start = DateTime.fromISO(range.start).toFormat("yyyy-MM-dd");
        const end = DateTime.fromISO(range.end).toFormat("yyyy-MM-dd");
        this.startDate = DateTime.fromFormat(start, "yyyy-MM-dd", { zone: "utc" }).minus({ days: 1 }).toISO();
        this.endDate = DateTime.fromFormat(end, "yyyy-MM-dd", { zone: "utc" }).plus({ days: 1 }).toISO();
        this.getTimeClocksRange();
      },
      deep: true,
    },
  },

  data: () => ({
    loading: false,
    timeClocks: [],
    chartdata: {
      labels: [],
      datasets: [],
    },

    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
        align: "end",
        fontColor: "white",
        labels: {
          padding: 15,
        },
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              display: false,
            },
            scaleLabel: {
              display: true,
              labelString: "Days",
              fontColor: "white",
            },
            ticks: {
              fontColor: "white",
            },
          },
        ],
        yAxes: [
          {
            display: true,
            gridLines: {
              display: true,
              color: "#888",
            },
            scaleLabel: {
              display: true,
              labelString: "Hours",
              fontColor: "white",
            },
            ticks: {
              fontColor: "white",
            },
          },
        ],
      },
    },

    pullKey: 0,

    jobFilterSelect: [],
    codeFilterSelect: [],
    categoryFilterSelect: [],

    range: {},
    startDate: DateTime.now().startOf("week").toISO(),
    endDate: DateTime.now().toISO(),
    inputProps: { solo: true, hideDetails: true },
    menuProps: { offsetY: true, closeOnContentClick: false },
    sumTotal: 0,
  }),
  methods: {
    ...mapActions("timeClock", ["getTimeClocks"]),
    getProps(array, propertyName) {
      return array.filter((e, i) => array.findIndex((a) => a[propertyName] === e[propertyName]) === i);
    },
    filterChange(filters) {
      this.filters = filters;
      this.doChart();
    },
    getChart() {
      this.doChart().then(() => {
        this.loading = false;
        this.hideLoadBar();
      });
    },
    resetChart() {
      this.jobFilterSelect = [];
      this.codeFilterSelect = [];
      this.categoryFilterSelect = [];
      this.$refs.dateRange.reset();
    },
    async doChart() {
      var self = this;
      self.chartdata = { labels: [], datasets: [] };
      if (this.timeClocks) {
        let hoursValues = [];
        const end = DateTime.fromISO(this.endDate).toUTC();
        const start = DateTime.fromISO(this.startDate).toUTC();
        const dateRange = end.diff(start, ["days"]);
        var days = Math.ceil(dateRange.toObject().days);
        console.log(days);
        let clocks = this.timeClocks;

        //filter by job
        if (this.jobFilterSelect?.length > 0) {
          const jobFilterIds = this.jobFilterSelect.map((a) => a.id);
          clocks = clocks.filter((c) => {
            return jobFilterIds.includes(c.jobId);
          });
        }

        if (this.codeFilterSelect?.length > 0) {
          const codeFilterIds = this.codeFilterSelect.map((a) => a.id);
          clocks = clocks.filter((c) => {
            return codeFilterIds.includes(c.costCodeId);
          });
        }

        if (this.categoryFilterSelect?.length > 0) {
          const categoryFilterIds = this.categoryFilterSelect.map((a) => a.id);
          clocks = clocks.filter((c) => {
            return categoryFilterIds.includes(c.timeCategoryId);
          });
        }
        for (var i = 0; i < days; i++) {
          let dd = end.plus({ days: i - days + 1 });
          let c = clocks.filter(
            ({ clockStart }) =>
              DateTime.fromISO(clockStart).toObject().year === dd.toObject().year &&
              DateTime.fromISO(clockStart).toObject().month === dd.toObject().month &&
              DateTime.fromISO(clockStart).toObject().day === dd.toObject().day,
          );
          var hours = 0;
          c.forEach((clock) => {
            if (!clock.clockEnd) {
              return;
            }
            let dateStart = DateTime.fromISO(clock.clockStart);
            let dateEnd = DateTime.fromISO(clock.clockEnd);
            const diff = dateEnd.diff(dateStart, ["hours"]).toObject().hours;
            hours += diff;
          });
          let dateLabel = dd.toObject().month + "/" + dd.toObject().day;
          self.chartdata.labels.push(dateLabel);
          hoursValues.push(hours.toFixed(2));
        }
        let hoursData = {
          label: "Daily Hours",
          backgroundColor: "rgba(242, 183, 28, .9)",
          borderColor: "rgba(242, 183, 28, 1)",
          borderWidth: 3,
          data: hoursValues,
        };
        self.chartdata.datasets.push(hoursData);
        let sumData = [...hoursData.data];
        this.sumTotal = this.sum(sumData).toFixed(2);
      }
    },
    getTimeClocksRange() {
      const params = {
        "paging.orderbyfield": "Id",
        "paging.ascending": "true",
        "paging.skipnum": 0,
        "paging.takenum": 9999,
      };
      params.clockstartafter = this.startDate;
      params.clockendbefore = this.endDate;
      this.init(params);
    },
    init(params = null) {
      this.loading = true;
      this.showLoadBar();
      TimeClockService.getTimeClocks(params).then((r) => {
        this.timeClocks = r.data;
        this.getChart();
      });
    },
  },
  mounted() {
    this.getTimeClocksRange();
  },
};
</script>
<style lang="scss">
.chart-wrap {
  background: rgb(99, 106, 112);
  padding: 15px;
}
.report-select {
  max-width: 180px;
  .v-label {
    font-size: 14px;
  }
}
</style>
