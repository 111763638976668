<script>
import { Bar } from "vue-chartjs";
export default {
  extends: Bar,
  props: {
    chartdata: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  watch: {
    data: function () {
      this.renderChart(this.data, this.options);
    },
  },
  computed: {
    data: function () {
      return this.chartdata;
    },
  },
  mounted() {
    this.renderChart(this.data, this.options);
  },
};
</script>
<style></style>
